import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { Subject } from "rxjs";

import { AiSendMessage } from "../models/aiSendMessage";
import { WebSocketMessage } from "../models/webSocketMessage";

@Injectable({
    providedIn: 'root'
})
export class WebSocketService {
    private retryNo: number = 0;
    private maxRetry: number = 10;
    private socket: WebSocket | null = null;
    private projectId: string | null = null;
    private anonymousId: string | null = null;
    private isRetrying: boolean = false;
    private isConnected: boolean = false;

    message: Subject<WebSocketMessage>

    constructor(private config: ConfigService) {
        this.message = new Subject<WebSocketMessage>();
    }

     // getters
    getWebsocketHost (): string {
        //const host = this.config.getHost();
        const host = this.config.getOrchestrationHost();
        const scheme = this.config.getWssScheme();
        const projectId = this.config.getProjectId();
        const anonymousId = this.config.getAnonymousId();
        const agentId = this.config.getAgentId();

        // TODO - old way, using RESTFUL api
        //return `${scheme}://${host}/agent/${projectId}/connection/${anonymousId}`;
        return`${scheme}://${host}/socket/project/${projectId}/agent/${agentId}/${anonymousId}`
    }


    getConnectionStatus (): boolean {
        return this.isConnected;
    }

    getMessageModel (type: string, content: string, language: string): AiSendMessage {
        return {
            type: type,
            content: content,
            language: language
        }
    }

    getMessages () {
        return this.message.asObservable()
    }

    // setters
    setProjectId(id: string | null) {
        this.projectId = id;
    }

    setAnonymousId (id: string) {
        this.anonymousId = id;
    }

    setConnectionStatus (status: boolean) {
        this.isConnected = status
    }

    async initConnection () {
        this.setProjectId(this.config.getProjectId());
        this.setAnonymousId(this.config.getAnonymousId());
        await this.connection();
    }

    async connection (): Promise<boolean> {
        if (this.socket) this.disconnect();

        this.socket = new WebSocket(this.getWebsocketHost());

        Event(this.socket, "open", () => this.onOpen());
        Event(this.socket, "close", () => this.onClose());
        Event(this.socket, "error", (e: any) => this.onError(e));
        Event(this.socket, "message", (msg: MessageEvent) => this.onMessage(msg))

        return new Promise(resolve => {
            const repeater = () => {
                setTimeout(() => {
                    console.log("Checking connection");

                    switch (this.socket?.readyState) {
                        case WebSocket.CONNECTING:
                            console.log("connecting");
                            return repeater();
                        case WebSocket.OPEN:
                            console.log("connected");
                            return resolve(true);
                        case WebSocket.CLOSING:
                        case WebSocket.CLOSED:
                            console.log("closing");
                            return resolve(false);
                        default:
                            console.log("undefined");
                            resolve(false);
                            this.socket = null;
                    }
                }, 500);
            };

            repeater();
        });
    }

    async reconnection () {
        if (this.isRetrying) return;

        this.isRetrying = true;

        const repeater = async () => {
            if (this.maxRetry <= this.retryNo) {
                this.isRetrying = false;
                this.retryNo = 0;
                console.error(`Could not connect to ws, max retry exceeded: ${this.maxRetry}`);
                return;
            }

            if (!await this.connection()) {
                this.retryNo++;
                setTimeout(repeater, 2000);
            } else {
                this.retryNo = 0;
                this.isRetrying = false;
            }
        };

        repeater().then();
    }

    sendMessage (message: AiSendMessage) {
        if (!this.getConnectionStatus()) return false;

        this.socket?.send(JSON.stringify(message));
        return true;
    }

    // wss event handlers
    onOpen () {
        this.setConnectionStatus(true);
        console.log("connection establish");
    }

    async onClose () {
        this.socket = null;
        this.setConnectionStatus(false);
        console.log("connection closed");
        await this.reconnection();
    }

    async onError (e: any) {
        this.socket = null;
        this.setConnectionStatus(false);
        console.log("connection error");
        //await this.reconnection();
    }

    onMessage (msg: MessageEvent) {
        const struct = JSON.parse(msg.data);
        const message: WebSocketMessage = {
            id: struct.id,
            type: struct.type,
            content: struct.answer,
            language: struct.language,
            audioAnswer: struct?.voice,
            attachments: struct?.attachments
        }

        if (message.type == "error") {
            message.content = struct.error
            message.language = "en-US"
        }

        switch (message.type) {
            case "health_check":
                this.sendMessage({type: "health_check", content: "", language: ""});
                break;
            default:
                this.message.next(message);
                break;
        }
    }

    disconnect(): void {
        if (this.socket) {
            RemoveEvent(this.socket, "open", () => this.onOpen());
            RemoveEvent(this.socket, "close", () => this.onClose());
            RemoveEvent(this.socket, "error", (e: any) => this.onError(e));
            RemoveEvent(this.socket, "message", (msg: MessageEvent) => this.onMessage(msg))
            this.socket.close();
        }

        this.socket = null;
    }
}


function Event (i: WebSocket, e: any, callable: any) {
    i.addEventListener(e, callable);
}

function RemoveEvent(i: WebSocket, e: any, callable: any) {
    i.removeEventListener(e, callable);
}
